<template>
  <div class="warp">
    <div class="select-box">
      <label class="label">网点</label>
      <van-dropdown-menu class="drop-down-class">
        <van-dropdown-item v-model="netId" :options="netList" @change="changeNet"/>
      </van-dropdown-menu>
    </div>
    <div class="select-box">
      <label class="label">点位</label>
      <van-dropdown-menu class="drop-down-class">
        <van-dropdown-item v-model="address" :options="vmcList"  @change="changeAddress"/>
      </van-dropdown-menu>
    </div>
    <div style="display: flex; justify-content: space-between">
      <van-tabs v-model:active="active" type="card" style="width:200px;margin-top: 10px;margin-left: -16px" @change="initData">
        <van-tab title="昨天" name="1"></van-tab>
        <van-tab title="上月" name="2"></van-tab>
        <van-tab title="本月" name="3"></van-tab>
        <van-tab title="本年" name="4"></van-tab>
      </van-tabs>
      <van-dropdown-menu class="drop-down-class">
        <van-dropdown-item v-model="sort" :options="sortList" @change="initData"/>
      </van-dropdown-menu>
    </div>
    <div class="data-box">
      <div class="cell-box">
        <div class="title first-title">机器点位</div><div class="count-num first-title">系数</div>
      </div>
      <div class="vmc-box" v-for="(item, key) in dataCount" :key="key">
        <div class="vmc-infos">
          <div class="label-title">编号：<span>{{ item.vmcName }}</span></div>
          <div class="label-title">网点：<span>{{ item.netName }}</span></div>
          <div class="label-title">点位：<span>{{ item.address }}</span></div>
        </div>
        <div class="out-nums" @click="toPath(item.vmcId)">
          <span class="active-three">
            {{ item.vmcRate.toFixed(2) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { networkList, vmcinfoList } from '../../assets/api/goodsSelect.js'
import { cronvmcrate } from '../../assets/api/ratio.js'
const dateNow = new Date();
const year = dateNow.getFullYear();
const month = dateNow.getMonth() > 8 ? dateNow.getMonth()+1 : `0${dateNow.getMonth()+1}`;
const pDayArr = new Date(dateNow.getTime() - 24*60*60*1000).toLocaleString().split(' ')[0].split('/')
const today = dateNow.getDate() > 9 ? dateNow.getDate() : `0${dateNow.getDate()}`;
pDayArr[1] = pDayArr[1]>9?pDayArr[1]:`0${pDayArr[1]}`
pDayArr[2] = pDayArr[2]> 9 ? pDayArr[2]: `0${pDayArr[2]}`
const yesToday = pDayArr.join('-')

export default {
  data() {
    return {
      netId: '',
      netList: [],
      address: '',
      vmcList: [],
      active: 1,
      year: year,
      month: month,
      yesToday: yesToday,
      dataCount: [],
      sort: '2',
      sortList: [
        { text: '从大到小', value: '2' },
        { text: '从小到大', value: '1' }
      ],
    }
  },
  mounted() {
    this.getNetData()
    this.getVmcList()
    this.initData()
  },
  methods: {
    toPath(vmcId) {
      this.$router.push({
        name: `net_point_detail_infos`,
        query: {
          vmcId: vmcId,
          sign: 'quehuoxinxi'
        }
      })
    },
    getNetData() {
      this.netList = []
      networkList({ current: 1, size: 1000 }).then(res => {
        if (res.data.data.records.length > 0) {
          this.netList.push({
            text: '请选择网点',
            value: ''
          })
          res.data.data.records.forEach(item => {
            const it = {
              text: item.networkName,
              value: item.id
            }
            this.netList.push(it)
          })
        }
      })
    },
    getVmcList() {
      this.vmcList = []
      this.vmcList.push({
        text: '请选择点位',
        value: ''
      })
      if (!this.netId) return
      vmcinfoList({ netId: this.netId }).then(res => {
        if (res.data.data.records.length > 0) {
          res.data.data.records.forEach(item => {
            const it = {
              text: item.address,
              value: item.address
            }
            this.vmcList.push(it)
          })
        }
      })
    },
    changeNet() {
      this.address = ''
      this.getVmcList()
      this.initData()
    },
    changeAddress() {
      this.initData()
    },
    initData() {
      let params = {
        address: this.address,
        netId: this.netId,
        current: 1,
        size: 1000,
        sort: this.sort
      }
      if (this.active == 1) {
        params.startDate = this.yesToday
        params.endDate = this.yesToday
      }
      if (this.active == 2) {
        // 如果本月是1月，则直接查去年12月数据
        if (this.month == 1) {
          params.startDate = `${this.year - 1}-12-01`
          params.endDate = `${this.year - 1}-12-31`
        } else {
          const d = new Date(this.year,this.month-1,0)
          const m = this.month-1
          params.startDate = `${this.year}-${m>9?m:`0${m}`}-01`
          params.endDate = `${this.year}-${m>9?m:`0${m}`}-${d.getDate()}`
        }
      }
      if (this.active == 3) {
        // 查询本月数据
        // 如果今天是1号，则直接return
        if (today == '01') {
          return
        }
        // 如果机器开启时间大于本月1号，则
        // if ()
        params.startDate = `${this.year}-${this.month}-01`
        params.endDate = this.yesToday
      }
      if (this.active == 4) {
        params.startDate = `${this.year}-01-01`
        params.endDate = this.yesToday
      }
      console.log('params===>', params)
      cronvmcrate(params).then(res => {
        this.dataCount = res.data.data.records
      })
      // this.dataCount = []
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-dropdown-menu__bar) {
  box-shadow: none;
}
.warp {
  padding: 15px 10px;
  .select-box {
    display: flex;

    .label {
      line-height: 48px;font-size: 16px;width: 50px
    }

    .drop-down-class {
      width: calc(100% - 50px)
    }
  }

  .count-class {
    margin: 15px 0;
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0.5px 0.5px 0.5px #6c5a5a;
    text-align: center;
    .count-nums, .label {
      height: 30px;
    }
    .count-nums {
      color: #333;
      font-weight: bold;
      line-height: 40px;
    }
    .label {
      line-height: 30px;
      color: #666;
    }
  }

  .data-box {
    margin-top: 15px;

    .cell-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      line-height: 20px;
      padding: 8px 0;
      .title {
        width: calc(100% - 100px);
      }
      .count-num {
        width: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      .first-title {
        font-size: 15px;
        color: #333;
        font-weight: bold;
      }
    }
    .vmc-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 10px;
      border-bottom: 1px solid #999;
      padding-bottom: 8px;
      .vmc-infos {
        width: calc(100% - 100px);
        text-align: left;
        height: auto;
        color: #666;
        .label-title {
          line-height: 20px;
        }
        span {
          color: #333;
        }
      }
      .out-nums {
        width: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #37C144;
        .active-three {
          border-bottom: 1px solid #37C144;
        }
      }
    }
  }
}
</style>
