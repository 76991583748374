import request from './request.js';

// 网点管理
export const networkList = (params) => {
  return request({
    url: '/api/blade-admin/network/list',
    method: 'get',
    params
  })
}

// 机器信息
export const vmcinfoList = (params) => {
  return request({
    url: '/api/blade-admin/vmcinfo/list',
    method: 'get',
    params
  })
}
// 机器信息
export const replenishment = (params) => {
  return request({
    url: '/api/blade-admin/statistics/h5/replenishment',
    method: 'get',
    params
  })
}